import React from 'react'
import ReactDOM from 'react-dom'

// Import the views your app loads
import {appDetailsState, LoadAnalytics, Onboarding, writeLog,} from 'trello-shared-resources'
import CardDeleteConfirmView from './views/CardDeleteConfirmView'
import Connector from './views/Connector'

// Globally load the ant design css
import 'antd/dist/antd.css'
import './css/main.css'
import 'trello-shared-resources/dist/assets/fonts/font.css'
import ErrorComponent from 'trello-shared-resources/dist/components/onboarding/error/ErrorComponent'
import Settings from 'trello-shared-resources/dist/components/settings/Settings'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorSection} from 'trello-shared-resources/dist/components/onboarding/error/ErrorSection'

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require('query-string')
const parsedQueryString = queryString.parse(window.location.search)
const iframePassedAppType = parsedQueryString['apptype']
writeLog('Iframe was passed apptype : ' + iframePassedAppType)

LoadAnalytics()

function getComponentToRender(iframePassedAppType) {
    switch (iframePassedAppType) {
        case 'card-delete-init' :
            return <CardDeleteConfirmView/>
        case 'onboarding' :
            // This automatically loads when your app is installed
            return <Onboarding context={appDetailsState}/>
        case 'settings' :
            // This is the settings page for your app
            return <Settings context={appDetailsState}/>
        case 'error' :
            return <ErrorComponent/>
        default :
            // We not pass a querystring param for the App Connector
            // This connector is used to instantiate your app's capabilities
            return <Connector/>
    }
}

ReactDOM.render(<ErrorBoundary fallbackRender={({error, resetErrorBoundary}) =>
        <ErrorSection errorMessage={error.message} setErrorMessage={resetErrorBoundary}
                      smallWindow={false}/>}> {getComponentToRender(iframePassedAppType)} </ErrorBoundary>
    , document.getElementById('root')
)