import {useEffect} from 'react'
import {initializeCapabilities, PageView, TrackActionEvent} from 'trello-shared-resources'

/*
    The Connector is used to tell Trello what capabilities your power-up should have.
    This Connector should be used to add new menu items, buttons and other similar capabilities.
*/
const ConnectorView = () => {
    const capabilities = {
        'card-buttons': function (t, opts) {
            return [{
                icon: './icons/cd-button.svg',
                text: 'Delete Card',
                callback: function (t, opts) {
                    const trelloContextInfo = t.getContext()
                    TrackActionEvent('Button Click', trelloContextInfo, {
                        card_id: trelloContextInfo.card,
                        button: 'Open Card_Delete'
                    })
                    return t.popup({
                        url: './index.html?apptype=card-delete-init',
                        accentColor: '#EBEDF0',
                        height: 150,
                        fullscreen: false,
                        title: 'Card Delete by Kolekti'
                    })
                },
                height: 200,
                condition: 'edit'
            }];
        },
    }

    useEffect(() => {
        PageView("PowerUp Connector");
    }, [])

    initializeCapabilities(capabilities)

    return null
}

export default ConnectorView;