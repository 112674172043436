import React, {Component} from 'react';
import CardDeleteConfirm from '../components/app/CardDeleteConfirm';
import {Skeleton} from 'antd'
import {LicenseDetailsContext, LicenseDetailsProvider, PageView,} from 'trello-shared-resources/dist'

class CardDeleteConfirmView extends Component {

    componentDidMount() {
        PageView('CardDelete');
    }

    render() {
        return (
            <LicenseDetailsProvider>
                <LicenseDetailsContext.Consumer>
                    {(licenseDetails) => {
                        if (licenseDetails.isLoading) {
                            return <Skeleton active={true} />
                        }
                        return <CardDeleteConfirm licenseDetails={licenseDetails} />
                    }}
                </LicenseDetailsContext.Consumer>
            </LicenseDetailsProvider>
        )
    }
}

export default CardDeleteConfirmView