import React, {Component} from 'react'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {getToken} from 'trello-shared-resources/dist/services/TokenService'
import {Button} from 'antd'
import {Link} from '@material-ui/core'
/**
 * Content from the card-delete modal
 */
class CardDeleteConfirm extends Component {

    constructor(props) {
        super(props)
        window.addEventListener('unload', () => {
            const trelloContext = this.getLicenseDetails().trelloIframeContext
            if (trelloContext) {
                const context = trelloContext.getContext()
                TrackActionEvent('Button Click', context, {
                    card_id: context.card,
                    button: 'Close Card_Delete'
                })
            }
        })
    }

    getCardURL(cardId, token, licenseDetailsContext) { // TODO - Perhaps put this in a separate component or class that is responsible for generating Trello REST URLs
        let trelloBaseUrl = 'https://api.trello.com/1/cards/' + cardId // TODO - Reference this from a central location
        let searchParameters = new URLSearchParams([['key', licenseDetailsContext.apiKey], ['token', token]])
        return trelloBaseUrl + '?' + searchParameters.toString()
    }

    getLicenseDetails() {
        return this.props.licenseDetails
    }

    async CardDeleteCallback(licenseDetailsContext) {
        if (!licenseDetailsContext.isLoading) {
            let trelloContext = licenseDetailsContext.trelloIframeContext
            let context = trelloContext.getContext()
            let token = await getToken()
                .then(function (token) {
                    return token
                })
            if (token) {
                let trelloRestURL = this.getCardURL(context['card'], token, licenseDetailsContext)
                await fetch(trelloRestURL, {method: 'DELETE'}).then(response => {
                    if (response.ok) {
                        // Only track the deletion if the response from the deletion was successful
                        TrackActionEvent('Button Click', context, {
                            card_id: context.card,
                            button: 'Delete Card'
                        })
                        trelloContext.closePopup()
                    } else {
                        this.setState({
                            status: 'error'
                        })
                    }
                }).catch(() => {
                    this.setState({
                        status: 'error'
                    })
                })
            } else {
                // Present an authorization error
                this.setState({
                    status: 'error'
                })
                // alert("ERROR: The card deletion cannot be processed because you have not given it permission.")
            }
        }
    }

    renderSuccess(licenseDetailsContext) {
        return (
            <div className="popup__wrapper">
                <p>All actions will be removed from the activity feed and you won't be able to re-open the card. There
                    is no undo.</p>
                <Button
                    type="primary"
                    danger
                    onClick={() => {
                        this.CardDeleteCallback(licenseDetailsContext)
                    }}>Delete
                </Button>
            </div>
        )
    }

    render() {
        if (!this.getLicenseDetails().isLicensed() || !this.getLicenseDetails().isAuthorized) {
            this.getLicenseDetails().trelloIframeContext.modal({
                url: './index.html?apptype=error',
                fullscreen: true,
                resizable: false,
                title: 'Card Delete by Kolekti',
                accentColor: '#EBEDF0'
            })
            return (<div>Loading...</div>)
        }

        if (!this.getLicenseDetails().trelloIframeContext || (this.state && this.state.status === 'error')) {
            const supportUrl = 'https://productsupport.adaptavist.com/servicedesk/customer/portal/51'
            return (
                <React.Fragment>
                    <p>Whoops! - Unfortunately there was an issue talking to Trello!</p>
                    <p>
                        Please refresh and try again, if that does not work then please contact support
                    </p>
                    <Link href={supportUrl} target="_blank"
                          underline="none">Help & Support</Link>
                </React.Fragment>
            )
        }
        return this.renderSuccess(this.getLicenseDetails())
    }

}

export default CardDeleteConfirm